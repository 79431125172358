<template>
  <highcharts
    :options="chartOptions"
    style="height: 300px !important"
  />
</template>

<script>
import Highcharts from 'highcharts';

export default {
  name: 'ComparativoQtdAisBdgd',
  props: {
    dados: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoomType: 'xy',
          style: {
            fontFamily: 'muli'
          }
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: [
          {
            categories: this.categories,
            crosshair: true
          }
        ],
        yAxis: [
          {
            labels: {
              format: '{value}%'
            },
            title: {
              text: '% Aderência'
            },
            max: 100,
            opposite: true
          },
          {
            gridLineWidth: 0,
            title: {
              text: 'QTD'
            }
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            name: 'BDGD',
            type: 'column',
            color: '#E6EE9C',
            yAxis: 1,
            data: this.bdgdSeriesData
          },
          {
            name: 'AIS',
            type: 'column',
            color: '#FFE082',
            yAxis: 1,
            data: this.aisSeriesData
          },
          {
            name: 'Aderência',
            type: 'line',
            color: '#D1C4E9',
            data: this.aderenciaSeriesData,
            tooltip: {
              valueSuffix: ' %'
            },
            marker: {
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors[3],
              fillColor: 'white'
            }
          }
        ],
        exporting: {
          menuItemDefinitions: {
            viewFullscreen: {
              text: 'Full Screen'
            }
          },
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen']
            }
          }
        }
      };
    },
    categories() {
      return this.dados.map((item) => item.tuc);
    },
    bdgdSeriesData() {
      return this.dados.map((item) => Number(item.bdgd));
    },
    aisSeriesData() {
      return this.dados.map((item) => Number(item.ais));
    },
    aderenciaSeriesData() {
      return this.dados.map((item) => Number(item.aderencia));
    }
  }
};
</script>
